
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  auth:false,
  data(){
    return{
      showPopupNotAccept:false,
      showLogin:true,
      checkData:{
        "thairathUser": false,
        "registered": false
      },
      emailToCheck:null
    }
  },
    computed: {
    ...mapState('config/site', [
      'isBlur',
      'siteConfig',
      'isScheduleShowOpen',
      'isLogInDialogShowOpen',
    ]),
  },
  created(){
    console.log("this.$auth.loggedIn",this.$auth.loggedIn)
     if(this.$auth.loggedIn){
        this.$router.push(this.localePath('/'))
    }
  },
  watch:{
    '$auth.loggedIn'(){
        if(this.$auth.loggedIn){
            this.$router.push(this.localePath('/'))
        }
    }
  },
  methods:{
        ...mapActions('authentication', ['checkThairathUser']),
    checkEmail(ev){
      this.checkThairathUser({email:ev}).then(data=>{
        console.log("data login",data)
        this.checkData=data.data
        if(this.checkData.thairathUser&&!this.checkData.registered){
          this.showLogin=false
          console.log("this.refs",this.$refs)
          this.emailToCheck=ev
        }
        if(!this.checkData.thairathUser&&!this.checkData.registered){
          this.showPopupNotAccept=true
        }
      })
    },
    showRegister(ev){
      console.log("ev showRegister",ev)
    }
  }
}
